import React, { useState } from "react";
import CandidateCV from "./CandidateCV";
import "../styles/CandidateForm.scss";
import "../styles/CandidateCV.scss";
import Checkbox from "@mui/material/Checkbox";
import { yellow } from "@mui/material/colors";
import Chip from "@mui/material/Chip";
// import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import DownloadPDF from "./DownloadPDF";

const employmentTypes = [
  "CDI",
  "Indépendant",
  "Freelance",
  "CDD",
  "Stage",
  "Alternance",
  "CDI Temps partiel",
  "CDD Temps partiel",
];

const countries = [
  "France",
  "Belgique",
  "Suisse",
  "Allemagne",
  "Espagne",
  "Italie",
  "Portugal",
  "Pays-Bas",
  "Royaume-Uni",
  "Irlande",
  "Autriche",
  "Suède",
  "Norvège",
  "Danemark",
  "Finlande",
  "Grèce",
  "Pologne",
  "Hongrie",
  "République Tchèque",
  "Slovaquie",
  "Roumanie",
  "Bulgarie",
  "Croatie",
  "Slovénie",
  "Estonie",
  "Lettonie",
  "Lituanie",
  "Luxembourg",
  "Malte",
  "Chypre",
].sort();

// const skillsList = [
//   { title: "Se baigner et s'habiller" },
//   { title: "Hygiène (dents/ongles)" },
//   { title: "Changer les couches" },
//   { title: "Apprentissage de la propreté" },
//   { title: "Préparation et stérilisation des biberons" },
//   { title: "Alimentation au biberon" },
//   { title: "Sevrage" },
//   { title: "Planification des repas, préparation des repas, batch cooking" },
//   { title: "Cadre de routine pour les bébés/tout-petits" },
//   { title: "Organisation de rendez-vous de jeu et de sorties" },
//   { title: "Activités pédagogiques et artisanales" },
//   { title: "Lecture aux enfants" },
//   { title: "Aide aux devoirs" },
//   { title: "Organisation des chambres/jouets" },
//   { title: "Administrer des médicaments avec le consentement des parents" },
//   { title: "Tenir des journaux pour les parents" },
//   { title: "Laverie pour enfants" },
//   { title: "La lessive des parents" },
//   { title: "Repassage" },
//   { title: "Ménage léger" },
//   { title: "Faire les courses (bureau de poste, ménage, épicerie)" },
//   { title: "Voyager en famille" },
//   { title: "Garde d'enfants" },
//   { title: "Parentalité par procuration/garde de nuit" },
// ];

const languagesList = [
  { title: "Anglais" },
  { title: "Français" },
  { title: "Espagnol" },
  { title: "Allemand" },
  { title: "Italien" },
  { title: "Chinois" },
  { title: "Japonais" },
  { title: "Coréen" },
  { title: "Arabe" },
  { title: "Russe" },
  { title: "Portugais" },
  { title: "Néerlandais" },
  { title: "Suédois" },
  { title: "Danois" },
  { title: "Norvégien" },
  { title: "Finnois" },
  { title: "Polonais" },
  { title: "Hongrois" },
  { title: "Tchèque" },
  { title: "Slovaque" },
  { title: "Roumain" },
  { title: "Bulgare" },
  { title: "Croate" },
  { title: "Slovène" },
];

const CandidateForm: React.FC = () => {
  const today = new Date();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    birthCountry: "",
    profilePicture: "",
    address: {
      city: "",
      postalCode: "",
      country: "",
    },
    phone: "",
    email: "",
    profile: "",
    englishLevel: "",
    languages: [] as string[], // Autres langues parlées couramment
    drivingLicense: false, // Permis
    availableDate: new Date(today.getTime() - today.getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10), // Date de disponibilité
    bio: "", // Biographie
    education: [
      {
        school: "",
        diploma: "",
        field: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    experienceYears: "", // Années d'expérience
    hasChildren: false, // Avez-vous des enfants ?
    childrenDetails: "", // Détails sur les enfants (nombre et âge) si 'Oui'
    hasPetAllergies: false, // Avez-vous des allergies aux animaux ?
    petAllergyDetails: "", // Détails des allergies si 'Oui'
    skills: [] as { skill: string; selected: boolean }[], // Champ de texte libre pour les compétences
    experiences: [
      {
        jobTitle: "",
        employmentType: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
        reasonForLeaving: "", // Raison du départ
        isOngoing: false,
      },
    ],
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    console.log(name, value, type);
    const fieldValue =
      type === "checkbox" && e.target instanceof HTMLInputElement
        ? e.target.checked
        : value;
    setFormData({ ...formData, [name]: fieldValue });
  };

  const handleChangeAddress = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [name]: value,
      },
    });
  };
  console.log(formData);

  // Fonction pour supprimer une formation, mais seulement s'il y en a plus d'une
  const removeEducation = (index: number) => {
    if (formData.education.length > 1) {
      const updatedEducation = formData.education.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        education: updatedEducation,
      });
    }
  };

  const handleEducationChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedEducation = [...formData.education];
    updatedEducation[index] = { ...updatedEducation[index], [name]: value };
    setFormData({ ...formData, education: updatedEducation });
  };

  const addEducation = () => {
    setFormData({
      ...formData,
      education: [
        ...formData.education,
        {
          school: "",
          diploma: "",
          field: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    });
  };

  const handleLanguageChange = (event: any, newValue: { title: string }[]) => {
    setFormData({
      ...formData,
      languages: newValue.map((option) => option.title),
    });
  };

  // const handleSkillChange = (event: any, newValue: { title: string }[]) => {
  //   setFormData({
  //     ...formData,
  //     skills: newValue.map((option) => ({
  //       skill: option.title,
  //       selected: true,
  //     })),
  //   });
  // };

  const handleExperienceChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedExperiences = [...formData.experiences];
    updatedExperiences[index] = { ...updatedExperiences[index], [name]: value };
    setFormData({ ...formData, experiences: updatedExperiences });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, drivingLicense: e.target.checked });
  };

  const handleCheckboxOnChange = (index: number) => {
    const updatedExperiences = [...formData.experiences];
    updatedExperiences[index].isOngoing = !updatedExperiences[index].isOngoing;
    if (updatedExperiences[index].isOngoing) {
      updatedExperiences[index].endDate = ""; // Reset la date de fin
    }
    setFormData({ ...formData, experiences: updatedExperiences });
  };

  const addExperience = () => {
    setFormData({
      ...formData,
      experiences: [
        ...formData.experiences,
        {
          jobTitle: "",
          employmentType: "",
          company: "",
          startDate: "",
          endDate: "",
          description: "",
          reasonForLeaving: "", // Raison du départ
          isOngoing: false,
        },
      ],
    });
  };
  const removeExperience = (index: number) => {
    const updatedExperiences = formData.experiences.filter((_, i) => i !== index);
    setFormData({ ...formData, experiences: updatedExperiences });
  };


  const handleChangeForm = (id: string, value?: boolean | string | null) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      const imageUrl = URL.createObjectURL(imageFile); // Génère une URL temporaire pour l'image
      setFormData({ ...formData, profilePicture: imageUrl });
    }
  };

  return (
    <div className="main-container">
      <div className="form-container">
        <form className="candidate-form">
          {/* Informations Personnelles */}
          <h5>Nanny Prestige CV : Remplir Ici</h5>
          <div className="input-group-grid">
            <div className="input-group-item">
              <label>Prénom:</label>
              <input
                className="form-field-short"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="input-group-item">
              <label>Nom:</label>
              <input
                className="form-field-short"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="input-group-item">
              <label>Âge:</label>
              <input
                className="form-field-short"
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </div>
            {/* Bouton de téléchargement d'image */}
            <div className="input-group-item">
              <label>Photo de profil:</label>
              <label className="upload-button">
                <CloudUploadIcon />
                TÉLÉCHARGER UNE PHOTO
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
            <div>
            <label>Adresse:</label>
            {[
              { name: "city", placeholder: "Ville", type: "text" },
              { name: "postalCode", placeholder: "Code Postal", type: "text" },
            ].map((field, index) => (
              <input
              key={index}
              className="form-field"
              type={field.type}
              name={field.name}
              placeholder={field.placeholder}
              value={formData.address[field.name as keyof typeof formData.address]}
              onChange={handleChangeAddress}
              />
            ))}
          <select
            className="form-field"
            name="country"
            value={formData.address.country}
            onChange={handleChangeAddress}
          >
            <option value="Sélectionnez un Pays">Sélectionnez un Pays</option>
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
            </div>

            <div>
            <label>Niveau d'anglais:</label>
            <select
              className="form-field"
              name="englishLevel"
              value={formData.englishLevel}
              onChange={handleChange}
            >
              <option value="">Sélectionner le niveau</option>
              {["Débutant", "Intermédiaire", "Avancé", "Courant", "Bilingue"].map((level, index) => (
              <option key={index} value={level}>
                {level}
              </option>
              ))}
            </select>
            </div>

          {/* Section Langues */}
          <div>
            <label>Autres langues parlées:</label>
            <Autocomplete
              multiple
              id="languages-autocomplete"
              options={languagesList}
              getOptionLabel={(option) => option.title}
              value={formData.languages.map((lang) => ({ title: lang }))}
              onChange={handleLanguageChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder="Sélectionnez les langues"
                />
              )}
            />
          </div>
          <div>
            <label>Permis:</label>
            {/* Utilisation du bouton Checkbox Material-UI */}
            <Checkbox
              checked={formData.drivingLicense}
              onChange={handleCheckboxChange}
              sx={{
                color: yellow[600],
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
            />{" "}
            Veuillez cliquer sur la case si vous avez un permis de conduire.
          </div>

          <div>
            <label>Date de disponibilité:</label>
            <input
              className="form-field"
              type="date"
              name="availableDate"
              value={formData.availableDate}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>Biographie:</label>
            <textarea
              className="form-field-large"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
              maxLength={500}
              placeholder="Max 500 caractères"
            ></textarea>
          </div>

          {/* Section Education */}
          <div>
            <h3>Formation</h3>
            {formData.education.map((edu, index) => (
              <div key={index}>
                <input
                  className="form-field"
                  type="text"
                  name="school"
                  placeholder="École"
                  value={edu.school}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <input
                  className="form-field"
                  type="text"
                  name="diploma"
                  placeholder="Diplôme"
                  value={edu.diploma}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <input
                  className="form-field"
                  type="text"
                  name="field"
                  placeholder="Domaine d'études"
                  value={edu.field}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <input
                  className="form-field"
                  type="date"
                  name="startDate"
                  placeholder="Date de début"
                  value={edu.startDate}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <input
                  className="form-field"
                  type="date"
                  name="endDate"
                  placeholder="Date de fin"
                  value={edu.endDate}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <textarea
                  className="form-field-large"
                  name="description"
                  placeholder="Description"
                  value={edu.description}
                  onChange={(e) => handleEducationChange(index, e)}
                />
              </div>
            ))}

            {/* Boutons Ajouter et Supprimer une formation */}
            <div style={{ marginTop: "10px" }}></div>
            <button type="button" onClick={addEducation} className="add-button">
              + AJOUTER UNE FORMATION
            </button>

            {/* Affichez le bouton "SUPPRIMER UNE FORMATION" uniquement si la longueur de education est supérieure à 1 */}
            <div style={{ marginTop: "10px" }}></div>
            {formData.education.length > 1 && (
              <button
                type="button"
                onClick={() => removeEducation(formData.education.length - 1)}
                className="remove-button"
              >
                - SUPPRIMER UNE FORMATION
              </button>
            )}
          </div>
          {/* Expérience professionnelle */}
          <div>
          <h3>Votre expérience</h3>
          {formData.experiences.map((experience, index) => (
            <div key={index}>
              <input
                className="form-field"
                type="text"
                name="jobTitle"
                placeholder="Intitulé de poste"
                value={experience.jobTitle}
                onChange={(e) => handleExperienceChange(index, e)}
              />
              <select
                className="form-field"
                name="employmentType"
                value={experience.employmentType}
                onChange={(e) => handleExperienceChange(index, e)}
              >
                <option value="">Type d'emploi</option>
                {employmentTypes.map((type, idx) => (
                  <option key={idx} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <input
                className="form-field"
                type="text"
                name="company"
                placeholder="Nom de l'entreprise"
                value={experience.company}
                onChange={(e) => handleExperienceChange(index, e)}
              />
              <input
                className="form-field"
                type="date"
                name="startDate"
                placeholder="Date de début"
                value={experience.startDate}
                onChange={(e) => handleExperienceChange(index, e)}
              />
              {!experience.isOngoing && (
                <input
                  className="form-field"
                  type="date"
                  name="endDate"
                  placeholder="Date de fin"
                  value={experience.endDate}
                  onChange={(e) => handleExperienceChange(index, e)}
                />
              )}
              <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
  <Checkbox
    checked={experience.isOngoing}
    onChange={() => handleCheckboxOnChange(index)}
    sx={{
      color: yellow[600],
      "&.Mui-checked": {
        color: yellow[600],
      },
    }}
  />
  <label style={{ marginLeft: "5px" }}>Depuis le</label>
</div>
              <textarea
                className="form-field-large"
                name="description"
                placeholder="Description"
                value={experience.description}
                onChange={(e) => handleExperienceChange(index, e)}
              />
              <input
                className="form-field"
                type="text"
                name="reasonForLeaving"
                placeholder="Raison du départ"
                value={experience.reasonForLeaving}
                onChange={(e) => handleExperienceChange(index, e)}
              />
            </div>
          ))}
          <button type="button" onClick={addExperience}>
            + Ajouter une expérience
          </button>
          {formData.experiences.length > 1 && (
            <button
              type="button"
              onClick={() => removeExperience(formData.experiences.length - 1)}
            >
              - Supprimer une expérience
            </button>
          )}
          </div>
          <div>
            <label>
              Combien d’années d’expérience dans la garde d’enfants avez-vous ?
            </label>
            <input
              className="form-field"
              type="text"
              name="experienceYears"
              value={formData.experienceYears}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>Avez-vous des enfants ?</label>
            <Checkbox
              checked={formData.hasChildren}
              onChange={(e) =>
                handleChangeForm("hasChildren", e.target.checked)
              }
              sx={{
                color: yellow[600],
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
            />
            {formData.hasChildren && (
              <div>
                <label>
                  Si oui, combien en avez-vous et quels âges ont-ils ?
                </label>
                <input
                  className="form-field"
                  type="text"
                  name="childrenDetails"
                  value={formData.childrenDetails}
                  onChange={(e) =>
                    handleChangeForm("childrenDetails", e.target.value)
                  }
                />
              </div>
            )}
          </div>

          <div>
            <label>Avez-vous des allergies aux animaux ?</label>
            <Checkbox
              checked={formData.hasPetAllergies}
              onChange={(e) =>
                handleChangeForm("hasPetAllergies", e.target.checked)
              }
              sx={{
                color: yellow[600],
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
            />
            {formData.hasPetAllergies && (
              <div>
                <label>Si oui, précisez :</label>
                <input
                  className="form-field"
                  type="text"
                  name="petAllergyDetails"
                  value={formData.petAllergyDetails}
                  onChange={(e) =>
                    handleChangeForm("petAllergyDetails", e.target.value)
                  }
                />
              </div>
            )}
          </div>

{/* Section Compétences */}
<div>
  <label>Compétences:</label>
  {formData.skills.map((skill, index) => (
    <div key={index} className="skill-item">
      <input
        type="text"
        className="form-field"
        name={`skill-${index}`}
        value={skill.skill}
        placeholder="Entrez une compétence"
        onChange={(e) => {
          const updatedSkills = [...formData.skills];
          updatedSkills[index].skill = e.target.value;
          setFormData({ ...formData, skills: updatedSkills });
        }}
      />
      <button
        type="button"
        onClick={() => {
          const updatedSkills = formData.skills.filter((_, i) => i !== index);
          setFormData({ ...formData, skills: updatedSkills });
        }}
        className="remove-skill-button"
      >
        Supprimer
      </button>
    </div>
  ))}
  <button
    type="button"
    onClick={() =>
      setFormData({
        ...formData,
        skills: [...formData.skills, { skill: "", selected: true }],
      })
    }
    className="add-skill-button"
  >
    + Ajouter une compétence
  </button>
</div>

          {/* Add the download button for the PDF */}
          <div style={{ marginTop: "20px" }}>
            <DownloadPDF formData={formData} />
          </div>
        </form>
      </div>

      {/* Autres sections (Expériences, etc.) */}
      <div className="display-on-tablet">
        <CandidateCV formData={formData} />
      </div>
    </div>
  );
};

export default CandidateForm;
